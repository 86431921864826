import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Autocomplete } from '@react-google-maps/api';
import { useTranslation } from 'react-i18next';
import './ItemList.css';

function ItemList() {
  const { t } = useTranslation();
  const [items, setItems] = useState([]);
  const [error, setError] = useState(null);
  const [search, setSearch] = useState('');
  const [category, setCategory] = useState('');
  const [categories, setCategories] = useState([]);
  const [location, setLocation] = useState('');
  const [searchRadius, setSearchRadius] = useState('20');
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [itemType, setItemType] = useState('posting');
  const autocompleteRef = useRef(null);

  useEffect(() => {
    async function fetchItems() {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/items`, {
          params: {
            search,
            category,
            location,
            radius: searchRadius,
            lat: latitude,
            lng: longitude,
            itemType,
          },
        });
        setItems(Array.isArray(response.data) ? response.data : []);
      } catch (error) {
        setError(t('Failed to load items'));
        console.error('Error fetching items', error);
      }
    }

    async function fetchCategories() {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/categories`);
        setCategories(Array.isArray(response.data) ? response.data : []);
      } catch (error) {
        console.error('Error fetching categories', error);
      }
    }

    fetchItems();
    fetchCategories();
  }, [search, category, location, searchRadius, latitude, longitude, itemType, t]);

  const handlePlaceSelect = () => {
    const place = autocompleteRef.current.getPlace();
    if (place && place.geometry) {
      const { lat, lng } = place.geometry.location;
      setLatitude(lat());
      setLongitude(lng());
      setLocation(place.formatted_address);
    }
  };

  const handleLocationChange = (e) => {
    const value = e.target.value;
    setLocation(value);

    if (!value) {
      setLatitude(null);
      setLongitude(null);
    }
  };

  const renderStars = (rating) => {
    const roundedRating = Math.round(rating * 2) / 2; // Round to nearest half
    return (
      <div className="star-rating-inline">
        {Array(5)
          .fill(0)
          .map((_, index) => {
            const starValue = index + 1;
            return (
              <span key={index} className="star">
                {starValue <= roundedRating ? (
                  <span className="filled">&#9733;</span> // Full star
                ) : starValue - 0.5 === roundedRating ? (
                  <span className="half-filled">&#9733;</span> // Half star
                ) : (
                  <span className="empty">&#9733;</span> // Empty star
                )}
              </span>
            );
          })}
      </div>
    );
  };

  return (
    <div className="item-list-container">
      <div className="item-type-toggle">
        <button
          className={`toggle-button ${itemType === 'posting' ? 'active' : ''}`}
          onClick={() => setItemType('posting')}
        >
          {t('Postings')}
        </button>
        <button
          className={`toggle-button ${itemType === 'looking-for' ? 'active' : ''}`}
          onClick={() => setItemType('looking-for')}
        >
          {t('Looking For')}
        </button>
      </div>

      <div className="filters">
        <input
          type="text"
          className="search-bar"
          placeholder={t('Search items...')}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <select
          className="category-filter"
          value={category}
          onChange={(e) => setCategory(e.target.value)}
        >
          <option value="">{t('All Categories')}</option>
          {categories &&
            Array.isArray(categories) &&
            categories.map((cat) => (
              <option key={cat.id} value={cat.id}>
                {cat.name}
              </option>
            ))}
        </select>
        <Autocomplete
          onLoad={(autoC) => (autocompleteRef.current = autoC)}
          onPlaceChanged={handlePlaceSelect}
          options={{
            types: ['geocode'],
            componentRestrictions: { country: ['de', 'it', 'ch'] },
          }}
        >
          <input
            type="text"
            placeholder={t('Search by location')}
            value={location}
            onChange={handleLocationChange}
          />
        </Autocomplete>
        <select
          className="radius-filter"
          value={searchRadius}
          onChange={(e) => setSearchRadius(e.target.value)}
        >
          <option value="5">+5km</option>
          <option value="10">+10km</option>
          <option value="20">+20km</option>
          <option value="50">+50km</option>
          <option value="100">+100km</option>
          <option value="200">+200km</option>
        </select>
      </div>

      {error && <p>{error}</p>}
      <div className="item-list">
        {items &&
          Array.isArray(items) &&
          items.map((item) => (
            <div className="item-list-item" key={item.id}>
              <Link to={`/items/${item.id}`}>
                <img src={item.imageurl} alt={item.title} className="item-image" />
              </Link>
              <div className="item-list-item-content">
                <Link to={`/items/${item.id}`} className="item-list-item-title">
                  {item.title}
                </Link>
                {item.average_rating != null && (
                  <div className="item-list-rating">
                    <span className="rating-number">
                      {parseFloat(item.average_rating).toFixed(1)} ({item.review_count})
                    </span>
                    {renderStars(item.average_rating)}
                  </div>
                )}
                <p className="item-list-item-description">{item.description}</p>
                <p className="item-list-item-price">{item.price} €/day</p>
                <p className="item-list-item-location">{item.location}</p>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

export default ItemList;
