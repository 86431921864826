import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Autocomplete } from '@react-google-maps/api';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook
import './AddItem.css';

function AddItem() {
  const { t } = useTranslation(); // Destructure t from useTranslation
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [image, setImage] = useState(null);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [location, setLocation] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [itemType, setItemType] = useState('posting'); // 'posting' or 'looking-for'
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const autocompleteRef = useRef(null);

  useEffect(() => {
    // Check if user is authenticated
    const token = localStorage.getItem('token');
    if (!token) {
      // If no token, redirect to login
      setError(t('loginRequired')); // Set error message (optional)
      setTimeout(() => {
        navigate('/login');
      }, 2000); // Delay for 2 seconds before redirecting
      return;
    }

    async function fetchCategories() {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/categories`);
        setCategories(response.data);
      } catch (error) {
        console.error('Error fetching categories', error);
      }
    }
    fetchCategories();
  }, [navigate, t]);

  const handlePlaceSelect = () => {
    const place = autocompleteRef.current.getPlace();
    if (place.geometry) {
      console.log('Place selected:', place);
      setLocation(place.formatted_address);
      setLatitude(place.geometry.location.lat());
      setLongitude(place.geometry.location.lng());
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    formData.append('price', price);
    formData.append('image', image);
    formData.append('categoryId', selectedCategory);
    formData.append('location', location);
    formData.append('latitude', latitude);
    formData.append('longitude', longitude);
    formData.append('itemType', itemType);

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/items`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      navigate('/items');
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        if (status === 401 || status === 403) {
          setError(t('loginRequired')); // Use translation for error message
          setTimeout(() => {
            navigate('/login');
          }, 2000);
        } else {
          setError(t('failedToAddItem')); // Use translation for error message
        }
        console.error('Error adding item:', error.response.data);
      } else {
        setError(t('failedToAddItem')); // Use translation for error message
        console.error('Error adding item:', error.message);
      }
    }
  };

  return (
    <div className="add-item-container">
      <h1>{t('addItemTitle')}</h1>
      {error && <p className="error-message">{error}</p>}
      <form onSubmit={handleSubmit}>
        <div>
          <label>
            <input
              type="radio"
              value="posting"
              checked={itemType === 'posting'}
              onChange={() => setItemType('posting')}
            />
            {t('posting')}
          </label>
          <label>
            <input
              type="radio"
              value="looking-for"
              checked={itemType === 'looking-for'}
              onChange={() => setItemType('looking-for')}
            />
            {t('lookingFor')}
          </label>
        </div>
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder={t('titlePlaceholder')}
          required
        />
        <textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder={t('descriptionPlaceholder')}
          required
        />
        <input
          type="number"
          value={price}
          onChange={(e) => setPrice(e.target.value)}
          placeholder={t('pricePlaceholder')}
          required
        />
        <label htmlFor="image-upload" className="image-upload-label">
          {t('chooseImage')}
          <input
            type="file"
            id="image-upload"
            onChange={(e) => setImage(e.target.files[0])}
            required={itemType === 'posting'} // Image is required only for posting
            style={{ display: 'none' }} // Hide the default file input
          />
        </label>
        <select 
          value={selectedCategory} 
          onChange={(e) => setSelectedCategory(e.target.value)}
          required={itemType === 'posting'} // Category is required only for posting
        >
          <option value="" disabled>{t('selectCategory')}</option>
          {categories.map((category) => (
            <option key={category.id} value={category.id}>
              {category.name}
            </option>
          ))}
        </select>
        <Autocomplete
          onLoad={(autoC) => (autocompleteRef.current = autoC)}
          onPlaceChanged={handlePlaceSelect}
          options={{
            types: ['(regions)'], // Restrict to regions (cities, districts)
            componentRestrictions: { country: ['de', 'it', 'ch']} // Restrict to a specific country (e.g., Germany)
          }}
        >
          <input
            type="text"
            placeholder={t('locationPlaceholder')}
            value={location}
            onChange={(e) => setLocation(e.target.value)}
          />
        </Autocomplete>
        <button type="submit">{t('addItemButton')}</button>
      </form>
    </div>
  );
}

export default AddItem;
